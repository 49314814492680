var adjustment;


$(document).ready(function(){


$.fn.setorderlist = function (type,el,data) {


    el.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+data+"'/>")

    var max_attempts = parseInt(el.attr('data-max-attempts'));
    var p_d = JSON.parse(data);
    var last_ans = p_d.slice(-1)[0];

      
    if(p_d.length >= max_attempts){
      el.find('.q-feedback').find('button').hide();
      $('.carousel-control-next').show();
    }else{
      $('.carousel-control-next').hide();  
    }

   
    if(last_ans.answer=="incorrect"){
      el.find('.q-content').hide();
    }else{
      el.find('.cs-cm-mcq.ol .sortTrue').addClass("ds-content");
      el.find('.cs-cm-mcq.ol .list-group-item').addClass("ds-content");
      // el.find('.cs-cm-mcq.ol .sortTrue').append("<div class='cs-overlay'></div>");
      el.find('.cs-cm-mcq.ol  button').hide();
      $('.carousel-control-next').show();
      el.attr('data-component-attempts',el.attr('data-max-attempts'))
    }


      el.find('.q-feedback.'+last_ans.answer).show();
      
      $sort = el.find('.sortTrue');

      $ls = el.find("div.list-group-item");

      $ls.each(function(){
        var val = $(this).attr('data-list');
        var t_val = last_ans.value.indexOf(val);
        $(this).attr('data-clist',t_val+1);
      })

      el.find("div.list-group-item").sort(sort_li).appendTo($sort);
       function sort_li(a, b){
        return ($(b).data('clist')) < ($(a).data('clist')) ? 1 : -1;    
      }

  },   


 

  $.fn.shuffle = function() {

 
        var allElems = this.get(),
            getRandom = function(max) {
                return Math.floor(Math.random() * max);
            },
            shuffled = $.map(allElems, function(){
                var random = getRandom(allElems.length),
                    randEl = $(allElems[random]).clone(true)[0];
                allElems.splice(random, 1);
                return randEl;
           });
 
        this.each(function(i){
            $(this).replaceWith($(shuffled[i]));
        });
 
        return $(shuffled);
 
    };

   
  

  $('.o-l .q-feedback button').click(function(){
    var ol = $(this).parents('.o-l')
    $('html, body').animate({ scrollTop: ol.offset().top}, 800, 'linear');
    ol.find('.q-feedback').hide();
    ol.find('.q-content').slideDown('slow');
    $('.cs-cm-mcq.ol .mt-1 button').show();  
  });
  

  $('.cs-cm-mcq.ol .mt-1 button').click(function(){
    var cls = "correct";
    var ol = $(this).parents('.o-l')
    var am_ans = ol.find('.attempts');
    var list = ol.find('.list-group-item');
    var component = $(this).closest("[data-component-status]");
    var attempts = parseInt(component.attr('data-component-attempts'));
    var max_attempts = parseInt(component.attr('data-max-attempts'));
    var current_page = $(this).closest("[data-page-status]");
    var idx = 0;
    var ans_g = [];
    score= 1;
    list.each(function(k,v){
      ans_g.push($(this).attr('data-list'))
      if((k+1)!=$(this).attr('data-list')){
        cls= 'incorrect';
        score= 0;
      }
      

    });
    $('.b-ws').trigger('click');
   
    am_val = am_ans.val();
    // var score = (idx/list.length).toFixed(2)

    if(!am_val)
      am_val = [{value:ans_g,answer:cls,score:score}];
    else{
      am_val= JSON.parse(am_val);
      am_val.push({value:ans_g,answer:cls,score:score});
    }
    am_ans.remove();
    ol.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+JSON.stringify(am_val)+"'/>")
    component['attempt'] = am_val;

    var ol_cb = $(this).parents('.c-b');
    $(ol_cb).animate({ scrollTop: ol_cb.offset()}, 800, 'linear');


    component.attr('data-component-status',1);
    $(this).submitlog(component,current_page,cls);



    component.attr('data-component-attempts',attempts+1);
    

    if(attempts+1 >=max_attempts){
      ol.find('.q-feedback.'+cls).find('button').hide();
    }

    // ol.find('.q-content').hide(); 
    $('html, body').animate({ scrollTop: ol.offset().top}, 800, 'linear');
    ol.find('.q-feedback.'+cls).slideDown('slow');    

    if(cls=="incorrect"){
      ol.find('.q-content').hide();
    } else {
      ol.find('.cs-cm-mcq.ol .sortTrue').addClass("ds-content");
      ol.find('.cs-cm-mcq.ol .list-group-item').addClass("ds-content");
      component.attr('data-component-attempts',component.attr('data-max-attempts'));

      // ol.find('.cs-cm-mcq.ol .sortTrue').append("<div class='cs-overlay'></div>");
      $(this).hide();
    }
    

  });  


var el = $('.sortTrue');
    $(el).each(function (i,e) {
      $(e).find('div.list-group-item').shuffle(); 
        var sortable = Sortable.create(e, {
            
           
        });
    })



  // Sortable.create(el, {
  //   group: "sorting",
  //   sort: true,
  //   chosenClass:"list-group-item"
  // });
});