$(document).ready(function() {

	//Adjust height depending upon box
	function fixAssessmentLeftBanner(){
		var bgObject  = $(".a-portal  .rgt");
		if(bgObject){
				var box_h = $('.a-portal').height();
				bgObject.css('height',box_h);
				var style = document.createElement("style");
				document.head.appendChild(style);
				sheet = style.sheet
				sheet.insertRule('.a-portal  .rgt::before { border-top: '+box_h+'px solid #fff }', 0);
		}
	}
	$(window).resize(function(){
		 $(".a-portal  .rgt").css('height','auto');
	   fixAssessmentLeftBanner();
	})
	fixAssessmentLeftBanner();



	if(!$.material)
		return false;
	$.material.init();
	Survey.defaultBootstrapMaterialCss.navigationButton = "btn";
	Survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
	Survey.Survey.cssType = "bootstrapmaterial";
	var json = $("#json_template").text();
	window.survey = new Survey.Model(json);

	/*Compare values*/
	function jsonEqual(a,b) {
			console.log("a...",JSON.stringify(a));
			console.log("b...",JSON.stringify(b));
			console.log("-------------------");
			return JSON.stringify(a) === JSON.stringify(b);
	}


	/*Removes unnecessary key*/
	function deepOmit(obj, keysToOmit) {
		var keysToOmitIndex =  _.keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit] ); // create an index object of the keys that should be omitted
		function omitFromObject(obj) { // the inner function which will be called recursivley
			return _.transform(obj, function(result, value, key) { // transform to a new object
				if (key in keysToOmitIndex) { // if the key is in the index skip it
					return;
				}

				result[key] = _.isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
			})
		}

		return omitFromObject(obj); // return the inner function result
	}


	/*On complete assessment*/
	survey.onComplete.add(function(survey, options) {
		reload_check = false;
		var correctAnswers = [];
		var p_json = $.parseJSON(json);

		/*parse json and collect correct answer*/
		$.each(p_json.pages, function(key, val) {
		  $.each(val.elements, function(i, v) {

		  var current_ans_val = v.correct;

		    if(typeof current_ans_val === 'object' && !(current_ans_val instanceof Array))
		        current_ans_val = deepOmit(current_ans_val, 'pos')


		    var question_type = v.type
		    switch (question_type) {
		      case "rating":
		        current_ans_val = current_ans_val.toString();
		        break;
		      case "checkbox":
		        current_ans_val = _.sortBy(current_ans_val);
		        break;
		      case "matrix":
		        var sorted_ary = {};
		        _(current_ans_val).keys().sort().each(function (key) {
		          sorted_ary[key] = current_ans_val[key];
		        });
		        current_ans_val  = sorted_ary;
		        break;
	        case "matrixdropdown":
	          var sorted_ary = {};
	          _(current_ans_val).keys().sort().each(function (ky) {
	            var tmp = {};
	            _.mapKeys(current_ans_val[ky], function(vn, kn){ tmp[kn] =  vn.toString() });
							var s_ary_i = {};
							_(tmp).keys().sort().each(function (k_i) {

								var tmp_i = {};
								// console.log(k_i,tmp);
								s_ary_i[k_i] = tmp[k_i]
							})
							// console.log("s_ary_i",s_ary_i);

	            sorted_ary[ky] = s_ary_i;


	          });
	          current_ans_val  = sorted_ary;

	        break;
		      default:

		    }
		    correctAnswers.push(current_ans_val)
		  })
		})

		// console.log(correctAnswers);

		var total =0 ,correct  = 0;
		$.each(survey.getAllQuestions(), function(key, val) {
			var type = val.getType();
			var current_value = val.value;

			if(typeof current_value === 'object' && !(current_value instanceof Array))
					current_value = deepOmit(current_value	, 'pos')



			switch (type) {
				case "checkbox":
					current_value = _.sortBy(current_value);
					break;
				case "matrix":
					var tmp_ary = {};
					_(current_value).keys().sort().each(function (ks) {
						tmp_ary[ks] = current_value[ks];
					});
					current_value = tmp_ary;
				break;
				case "matrixdropdown":
					var sorted_ary = {};
					_(current_value).keys().sort().each(function (ky) {
						var tmp = {};
						_.mapKeys(current_value[ky], function(vn, kn){ tmp[kn] = vn.toString() });
						sorted_ary[ky] = tmp;
					});
				current_value  = sorted_ary;
				break;
				default:
			}
			if(type!=="text" &  type!=="html"){
				total++
				console.log(current_value,"======",JSON.stringify(correctAnswers[key]));
				var is_true = jsonEqual(current_value,correctAnswers[key]);
				if(is_true)
					correct++;
			}
		})



		var score = (correct/total)*100
		score = score.toFixed(1);
		$.post('/save-user-assessment',{assessment_id:$("#assessment_id").val(),assessment_results:JSON.stringify(survey.data),assessment_score:score},function(data){
			var complete_text = "<a href=\"/assessment\" class=\"sv_complete_btn btn \">Retry</a>";
			if(data.assessment_status){
				$('.p-text b').html(score+'%');
				$('.p-text').show();
				var complete_text = "<a href=\"/dashboard\" class=\"sv_complete_btn btn \">Continue</a>";
			}else{
				$('.f-text b').html(score+'%');
				$('.f-text').show()
			}
			survey.completedHtml = complete_text;
			$(".i-text").css('display', 'none');
			$("#assessment_desc").css('display', 'none');
			$("#assessment_title").html('Your results');
			survey.render();
		})

	});
	$("#surveyElement").Survey({
			model: survey
	});
})
