function Config() {
    "use strict";
}


Config.endpoint = "http://deakintest.deakinlrs.com/xapi/";
Config.user = "TXItoiNh8929CMPu";
Config.password = "g1TwyV463676HZF97iVo4205";

//Storage Keys
var storageKeyName = "xapi/name";
var storageKeyEmail = "xapi/email";

function userLogin( name, email ) {
    //Assign Static 
    //Assign value to 
    var name = "kamlesh likhare";
    var email = "kamlesh.likhare@gmail.com";
    setActor(name, email);
}

function setActor( name, email ) {
    setUserName(name);
    setUserEmail(email);
}

function setUserName(name) {
    localStorage.setItem(storageKeyName, name);
}

function setUserEmail(email) {
    localStorage.setItem(storageKeyEmail, email);
}
