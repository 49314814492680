wrapper = ADL.XAPIWrapper;



wrapper.changeConfig({
   endpoint: Config.endpoint,
   "auth" : "Basic " + toBase64(Config.user+":"+Config.password),
});





function submission() {
  // This statement was generated using http://adlnet.github.io/xapi-lab/
  var statement = {
      "actor": {
          "mbox": "mailto:tyler.mulligan.ctr@adlnet.gov",
          "name": "Tyler",
          "objectType": "Agent"
      },
      "verb": {
          "id": "http://adlnet.gov/expapi/verbs/completed",
          "display": {
              "en-US": "completed"
          }
      },
      "object": {
          "id": "http://adlnet.gov/expapi/tutorials/xapiwrapper/assessment",
          "definition": {
              "name": {
                  "en-US": "xAPI Wrapper Assessment Demo"
              },
              "description": {
                  "en-US": "Demonstrating xAPI Wrapper used with an assessment form"
              }
          },
          "objectType": "Activity"
      }
  };
 
  // Dispatch the statement to the LRS
  ADL.XAPIWrapper.sendStatement(statement);
  alert("Thanks for submitting!");
  return false; // stops the page from refreshing
 }  
 // submission();