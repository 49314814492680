$(document).ready(function(){

	$('.intq').click(function(){
    		var component = $(this).closest("[data-component-status]");
			var status = component.attr('data-component-status');
			if(status!=1){
				component.attr('data-component-status',1);
				component.attr('data-component-attempts',1);
    			var current_page = $('#pageCarousel').find(".carousel-item.active");
				component.submitlog(component,current_page);
			}

			var id = $(this).attr('href');
			var audio = $(id).find('audio');

			var intq = $(this).parents('.int-q');
			var a_ls = intq.find('audio')	;
			a_ls.each(function(k,v){
				var a = $(this)
				a[0].currentTime = 0
				a[0].pause();
			})	
			if($(this).hasClass('collapsed'))
				audio[0].play();
		
	});	
  
});	  