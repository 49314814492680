$(document).ready(function(){
        $(document).on('touchstart click ', '.favCls', function(event) { 

                if(event.handled === false) return
                event.stopPropagation();
                event.preventDefault();
                event.handled = true;

                var block_id = $(this).attr('data-id');
                var this_ = $(this);
            $('<div id="overlay-50"><div class="loader_"></div></div>').insertAfter('.app-body');
                $.ajax({
                    headers: { 'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content') },
                    type: 'get',
                    url: '/favorite',
                    data: { id: block_id },
                    cache: false,
                    success:function(response){
                        
                        $(this_).html('<i class="cefont icon-star-filled"></i>');
                        $('.fav'+block_id).removeClass('favCls');
                       $('.fav'+block_id).addClass('unFavCls');
                       setTimeout(function(){
                        $('#overlay-50').remove()
                      },1000);
                       ;
                    }
                });
        });
        $(document).on('touchstart click', '.unFavCls', function(event) { 

          if(event.handled === false) return
                event.stopPropagation();
                event.preventDefault();
                event.handled = true;

            $('<div id="overlay-50"><div class="loader_"></div></div>').insertAfter('.app-body');

               var block_id = $(this).attr('data-id');
               var this_ = $(this);
               $.ajax({
                    headers: { 'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content') },
                    type: 'get',
                    url: '/unfavorite',
                    data: { id: block_id },
                    cache: false,
                    success:function(response){
                       
                       //$(this_).attr('class', 'favCls pull-right fav'+block_id);
                       $(this_).html('<i class="cefont icon-star"></i>');
                       $('.fav'+block_id).addClass('favCls');
                       $('.fav'+block_id).removeClass('unFavCls');
                       setTimeout(function(){
                        $('#overlay-50').remove()
                      },1000);
                    }
                });
        });         
});