$(document).ready(function(){
    $.fn.setmcq = function (type,el,data) {

        el.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+data+"'/>");
    
        var p_d = JSON.parse(data);
        var max_attempts = parseInt(el.attr('data-max-attempts'));
        var last_ans = p_d.slice(-1)[0];
        if(p_d.length >= max_attempts) {
          el.find('.q-feedback').find('button').hide();
          $('.carousel-control-next').show();
        }else{
          $('.carousel-control-next').hide();  
        }

        el.find('.q-content .cs-cm-mcq').hide();

        if(type == "ss"){
            if(last_ans.answer=="true") {
                el.find('.options li').eq(parseInt(last_ans.value)-1).addClass('active');             
                el.find('.q-feedback.correct').show();
            } else {
                el.find('.q-feedback.incorrect').show();
            }
        } else {
            var hm = "";
            var ls = el.find('li');
            var i = 0;

            ls.each(function(k,v){
                var tv = $(this).attr('data-value');
                var kv = k+1 ;
                if($.inArray(kv,last_ans.value) != -1) {
                    $(this).find('input[type=checkbox]').attr('checked','checked');
                }

                if(tv=='true'){
                    i++;
                    hm = hm+"<li>"+$(this).find('.tx').html()+"</li>";
                }
            });

            el.find('.q-feedback.pcorrect .lst').html(hm);
            el.find('.q-feedback.'+last_ans.answer).show();
        }
        if(last_ans.answer == "true" || last_ans.answer == "correct"){
            el.find('.options-main,.options').addClass("ds-content");

            // el.find('.options-main').append("<div class='cs-overlay'></div>");
            el.find('.q-content .cs-cm-mcq').show();
            el.find('.q-feedback').hide();
            el.find('.q-feedback.correct').show();
            el.find('.cs-cm-mcq button').hide();
            $('.carousel-control-next').show();
            el.attr('data-component-attempts',el.attr('data-max-attempts'))
        } else {
            el.find('.q-content .cs-cm-mcq').hide();
        }
    }   
    $(".cs-cm-mcq.ml .question button").click(function(){
        var mcq = $(this).parents('.c-b');
        var ls = $(this).parent().find('li');
        var er = 0;
        var er_ls = 0;
        var cls = 'correct';
        var hm = "";
        var hm_count = 0;
        var ans_ary = [];
        var correct = 0;
        ls.each(function(k,v){
            var tv = $(this).attr('data-value');
            var cv = $(this).find('input').is(":checked");
            if(tv=='true') {
                hm = hm+"<li>"+$(this).find('.tx').html()+"</li>";
                hm_count++;
            }
            if(tv=='true' && cv.toString()=="true"){
                er++;
                ans_ary.push(k+1);
                correct++;
            }
            if(tv=='false' && cv.toString()=="true"){
                er_ls = 1;
            }
            if(tv=='false' && cv.toString()=="false"){
                correct++;
            }      
        });
        $('.b-ws').trigger('click');
        var score = (correct/ls.length).toFixed(2);

        if(er ==0 || er_ls==1){
            cls = 'incorrect';
            mcq.find('.q-content .cs-cm-mcq').hide(); 

        }else if(er >0 && er != hm_count && er_ls==0){
            cls = 'pcorrect';
            mcq.find('.q-content .cs-cm-mcq').hide(); 
        }
        // mcq.find('.q-content').hide(); 
        $(mcq).animate({ scrollTop: mcq.offset().top}, 800, 'linear');
        mcq.find('.q-feedback.pcorrect .lst').html(hm);
        var component = $(this).closest("[data-component-status]");
        if(cls=="correct"){
            mcq.find('.options-main,.options').addClass("ds-content");
            // mcq.find('.options-main').append("<div class='cs-overlay'></div>");
            mcq.find('.cs-cm-mcq.ml button').hide();
             component.attr('data-component-attempts',component.attr('data-max-attempts'))
        }

        mcq.find('.q-feedback.'+cls).slideDown('slow');


        var max_attempts = parseInt(component.attr('data-max-attempts'));
        var current_page = $(this).closest("[data-page-status]");
        var attempts = parseInt(component.attr('data-component-attempts'));

        var am_ans = mcq.find('.attempts');
        component.attr('data-component-attempts',attempts+1);

        if(attempts+1 >= max_attempts){
          mcq.find('.q-feedback.'+cls).find('button').hide();
        }
        am_val = am_ans.val();

        if(!am_val) {
            am_val = [{value:ans_ary,answer:cls,score:score}];
        } else {
            am_val= JSON.parse(am_val);
            am_val.push({value:ans_ary,answer:cls,score:score});
        }
        am_ans.remove();
        mcq.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+JSON.stringify(am_val)+"'/>")
        component['attempt'] = am_val;
        $(this).submitlog(component,current_page,cls);
    });
    $(".cs-cm-mcq.sl .question button").click(function(){
        var mcq = $(this).parents('.c-b');
        $('.b-ws').trigger('click');
        var el = $(this).parent().find('.active');
        var idx = el.attr('data-value');
        if(typeof idx === "undefined") {
          return false
        }
        $(mcq).animate({ scrollTop: mcq.offset().top}, 800, 'linear');
        var cls = 'incorrect';
        var component = mcq.find("[data-component-status]");
        var attempts = parseInt(component.attr('data-component-attempts'));
        var max_attempts = parseInt(component.attr('data-max-attempts'));
        var current_page = $(this).parents(".carousel-item.active");
        var am_ans = mcq.find('.attempts');
        var score = 0;
        component.attr('data-component-attempts',attempts+1);
        if(idx == "true") {
          cls = "correct";
          score = 1;
        } else {

          mcq.find(".q-content .cs-cm-mcq").hide();
        }
        if(cls=="correct"){
          mcq.find('.options-main,.options').addClass("ds-content");
          component.attr('data-component-attempts',component.attr('data-max-attempts'))
          
          // mcq.find('.options-main').append("<div class='cs-overlay'></div>");
          mcq.find('.cs-cm-mcq.sl button:contains(SUBMIT)').hide();
        }
        mcq.find('.q-feedback.'+cls).slideDown('slow');

        if((attempts+1)>=max_attempts){
            mcq.find('.q-feedback.'+cls).find('button').hide();
        }
        mcq.find('.q-feedback'+cls).slideDown('slow');
        am_val = am_ans.val();
        if(!am_val) {
            am_val = [{value:el.attr('data-index'),answer:idx,score:score}];
        } else {
            am_val= JSON.parse(am_val);
            am_val.push({value:el.attr('data-index'),answer:idx,score:score});
        }
        am_ans.remove();
        mcq.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+JSON.stringify(am_val)+"'/>")
        component['attempt'] = am_val;
        $(this).submitlog(component,current_page,cls);
    });
    $(".cs-cm-mcq .options li.r").click(function(){
        $(this).parent().parent().find('button').removeClass('disabled');
        $(this).parent().parent().find('button').bind('click');
        $(this).parent().find('.active').removeClass('active');
        $(this).addClass('active');
    });

    $("div[data-component-type='mcq_ms'] .q-feedback button,div[data-component-type='mcq_ss'] .q-feedback button").click(function(){
        var mcq = $(this).parents('.c-b');
        mcq.find('.q-feedback').hide();
        mcq.find('.q-content .cs-cm-mcq').slideDown('slow');
        // $(this).parent().prev().slideDown('slow')
    });
});
