

$(document).ready(function(){


  $.fn.setgraphic_position = function (type,el,data) {


    el.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+data+"'/>")

      var max_attempts = parseInt(el.attr('data-max-attempts'));
    var p_d = JSON.parse(data);
    var last_ans = p_d.slice(-1)[0];

    
    if(p_d.length >= max_attempts){
      el.find('.q-feedback').find('button').hide();
      $('.carousel-control-next').show();  
    }else{

          $('.carousel-control-next').hide();  
        }
   

      // el.find('.q-content').hide();
      el.find('.q-feedback.'+last_ans.answer).show();

    var h = el.find("input[name$='horizontal']");
    var v = el.find("input[name$='vertical']");
    var r = el.find("input[name$='rotate']");

    var r_h = last_ans.value[0];
    var r_v = last_ans.value[1];
    var r_r = last_ans.value[2];

    h.attr('data-current-value',r_h);
    v.attr('data-current-value',r_v);
    r.attr('data-current-value',r_r);

     el.find($('.'+r_h)).removeClass('hidden');
     var scope = $(el).find('.slider-scope');
     var scope_goggles = $(el).find('.slider-scope-goggles');

    scope.css('margin-top',r_v+"px");
    scope_goggles.css({ "transform": "rotate("+r_r+"deg)", "-webkit-transform": "rotate("+r_r+"deg)", "-moz-transform": "rotate("+r_r+"deg)" });

    if(last_ans.answer == "correct"){
      el.find('.gps-submit').hide();
      $('.carousel-control-next').show();
       el.attr('data-component-attempts',el.attr('data-max-attempts'))
    }else{
      el.find('.q-content').hide();   
    }
    el.find('.move-p').hide();
           

  },     
  


  $.fn.extend({
    get_random :function (list) {
      list = list.split(',');
      return list[Math.floor((Math.random()*list.length))];
    },

    sliderResetRatio: function () {
      var g_ps = $('.g-ps .ps');
      // g_ps.each(function(){
      //   var m_img = $(this).find('.m-img');
      //   var s_img = $(this).find('.slider-right img')
      //   var mw = m_img.width()
      //   var o_mw = m_img[0].naturalWidth ;
      //   var rt =  mw/o_mw
      //   var sw = s_img[0].naturalWidth;
      //   s_img.css('width',sw*rt)

      // });
    }
  });
  $(document).sliderResetRatio();
  
  $( window ).resize(function() {
    $(document).sliderResetRatio();
  });
  var g_ps = $('.g-ps .ps');
  g_ps.each(function(){
   
    var s_ps = $(this).find('.image-container');
    var scope = $(s_ps).find('.slider-scope');
    var scope_goggles = $(s_ps).find('.slider-scope-goggles');
    var h = s_ps.find("input[name$='horizontal']");
    var v = s_ps.find("input[name$='vertical']");
    var r = s_ps.find("input[name$='rotate']");
  
    var r_h = $(document).get_random(h.val());
    var r_v = $(document).get_random(v.val());
    var r_r = $(document).get_random(r.val());

    h.attr('data-current-value',r_h);
    v.attr('data-current-value',r_v);
    r.attr('data-current-value',r_r);

    s_ps.find($('.'+r_h)).removeClass('hidden');

    scope.css('margin-top',r_v+"px");
    scope_goggles.css({ "transform": "rotate("+r_r+"deg)", "-webkit-transform": "rotate("+r_r+"deg)", "-moz-transform": "rotate("+r_r+"deg)" });
  });  
  $('.move-p').click(function(){
    var g_ps = $(this).parents('.g-ps');
    var s_ps = g_ps.find('.image-container');
    var scope = $(s_ps).find('.slider-scope');
    var scope_goggles = $(s_ps).find('.slider-scope-goggles');
    var d_p = $(this).attr('data-change-type');
    var ar = s_ps.find("input[name$='"+d_p+"']");
 
    var d_ary = ar.val();  
    var c_v = ar.attr('data-current-value');
    d_ary = d_ary.split(',');
    var c_i = d_ary.indexOf(c_v.toString());

    c_i++
    if(c_i==(d_ary.length))
      c_i = 0

    ar.attr('data-current-value',d_ary[c_i]);
    if(d_p=="horizontal"){
      // s_ps.css('margin-left',d_ary[c_i]+"px");
      s_ps.find($('.'+d_ary[c_i])).removeClass('hidden').siblings().addClass('hidden');
    }else if(d_p=="vertical"){

      scope.css('margin-top',d_ary[c_i]+"px");
    }else{
      scope_goggles.css({ "transform": "rotate("+d_ary[c_i]+"deg)", "-webkit-transform": "rotate("+d_ary[c_i]+"deg)", "-moz-transform": "rotate("+d_ary[c_i]+"deg)" });
    }
  });



  $('.gps-t-b').click(function(){
    var g_ps = $(this).parents('.g-ps');

     g_ps.find('.q-feedback').hide(); 
     g_ps.find('.gps-submit').show(); 
     $('html, body').animate({ scrollTop: g_ps.offset().top-50}, 800, 'linear');

    g_ps.find('.q-content').slideDown('slow');
    g_ps.find('.move-p').show();


  });


  $('.gps-submit').click(function() {


    var g_ps = $(this).parents('.g-ps');
    var component = $(this).closest("[data-component-status]");
    var attempts = parseInt(component.attr('data-component-attempts'));
    var max_attempts = parseInt(component.attr('data-max-attempts'));
    var current_page = $(this).closest("[data-page-status]");
    var h = g_ps.find("input[name$='horizontal']");
    var v = g_ps.find("input[name$='vertical']");
    var r = g_ps.find("input[name$='rotate']");
    var am_ans = g_ps.find('.attempts');
    var cls = 'incorrect';
    var ans_g = [h.attr('data-current-value'),v.attr('data-current-value'),r.attr('data-current-value')];
    var idx=0;

    if(h.attr('data-current-value') == h.attr('data-correct'))
      idx++
    if(v.attr('data-current-value') == v.attr('data-correct'))
      idx++
    if(r.attr('data-current-value') == r.attr('data-correct'))
      idx++

    if(idx==3)
      var cls = 'correct'
   
    am_val = am_ans.val();
    $('.b-ws').trigger('click');
    if(!am_val)
      am_val = [{value:ans_g,answer:cls,score:(idx/3)}];
    else{
      am_val= JSON.parse(am_val);
      am_val.push({value:ans_g,answer:cls,score:(idx/3)});
    }
    am_ans.remove();
    g_ps.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+JSON.stringify(am_val)+"'/>")
    component['attempt'] = am_val;

    component.attr('data-component-status',1);
    $(this).submitlog(component,current_page, cls);

    component.attr('data-component-attempts',attempts+1);
   
    if(attempts+1 >=max_attempts){
      g_ps.find('.q-feedback.'+cls).find('button').hide();
    }
    g_ps.find('.q-feedback').hide(); 
    
    $('html, body').animate({ scrollTop: g_ps.offset().top-50}, 800, 'linear');
    if(cls != "correct"){
      g_ps.find('.q-content').hide();
    }else{
      g_ps.find('.gps-submit').hide();
      component.attr('data-component-attempts',component.attr('data-max-attempts'))

    }
    g_ps.find('.move-p').hide();
    g_ps.find('.q-feedback.'+cls).show(); 
  });
});