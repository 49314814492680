jQuery.fn.extend({

  setActivityProgress: function (data) {
    
    console.log(data.stages);
    var stages = data.stages;
    this.setActiveStage(stages);
  },

  setActiveStage: function (stages) {
    var i = 0;
    var ls = 0;
    var $stg_count = Object.keys(stages).length;
  
    if($stg_count>1)
      $('.carousel-control-prev').show();

    var self = this ;
    $(".carousel-item").removeClass('active');    

    $.each(stages,function(k,v){
      i++;
      var stage_id = k
      var carouselNavElements = $(".carousel-indicators-p li[data-stageid="+stage_id+"]")
      //set the enabled attribute to true for the carousel's nav elements
      carouselNavElements.removeClass('li-disabled').removeClass('active');
      carouselNavElements.prop('disabled',false);
      carouselNavElements.attr('data-stage-status',v.status);
      if(i==$stg_count){
          ls = 1
          carouselNavElements.addClass('active');

      }
    
      self.setActivePages(v.pages,ls,stage_id)


    });
  },

  setActivePages: function (pages,ls,stage_id) {
    var self = this ;
    var i = 0;

    var $pg_count = Object.keys(pages).length;
    var c_length = $(this).find('.carousel-item').length;
    if($pg_count>1)
      $('.carousel-control-prev').show();
    


    $.each(pages,function(k,v){
      i++;

      var page_el = $(".carousel-item[data-page-id="+k+"]");
      page_el.attr('data-page-status',v.status);
      // console.log(Object.keys(pages).length );
      if(i==Object.keys(pages).length && ls==1)
        page_el.addClass('active');

      if(page_el.index() == c_length-1)
          $('.carousel-control-next').hide();
      
      self.setActiveBlocks(v.blocks)

    });
  },

  setActiveBlocks: function (blocks) {
    var self = this ;
    $.each(blocks,function(k,v){
      var block_el = $(".carousel-item section[data-block-id="+k+"]");
      block_el.attr('data-block-status',v.status);
      self.setActiveComponents(v.components)

    });
  },  
  setActiveComponents: function (components) {
    var self = this ;

    $.each(components,function(k,v){
      var comp_el = $("div[data-component-id="+k+"]");
      comp_el.attr('data-component-status',v.status);
      var d = decodeURIComponent(v.component_attempts);
      // comp_el.attr('data-component-attempts',JSON.parse(decodeURIComponent(v.component_attempts)));
      var comp_type = comp_el.attr('data-component-type');

      switch(comp_type){
        case 'mcq_ss' : 
        case 'mcq_ms' :
          if(d!="undefined")
            comp_el.attr('data-component-attempts',$.parseJSON(d).length);
          if(v.status == 1 && d!="undefined")
            $(document).setmcq(comp_type.split('_')[1],comp_el,d);
          else
            $('.carousel-control-next').hide();
        break;
        case 'orderlist' :
          if(d!="undefined")
            comp_el.attr('data-component-attempts',$.parseJSON(d).length);
          if(v.status == 1 && d!="undefined")
            $(document).setorderlist(comp_type.split('_')[1],comp_el,d);
          else
            $('.carousel-control-next').hide();
        break;
        case 'interactive_ques' :

          if(v.status == 0)
            $('.carousel-control-next').hide();
          else
            comp_el.attr('data-component-attempts',1);
        break;        
        case 'gslider' :
          if(d!="undefined")
            comp_el.attr('data-component-attempts',$.parseJSON(d).length);
          if(v.status == 1 && d!="undefined")
            $(document).setgraphic_slider(comp_type.split('_')[1],comp_el,d);
          else
            $('.carousel-control-next').hide();        
        break;

        case 'gposition' :
          if(d!="undefined")
            comp_el.attr('data-component-attempts',$.parseJSON(d).length);
          if(v.status == 1 && d!="undefined")
            $(document).setgraphic_position(comp_type.split('_')[1],comp_el,d);
          else
            $('.carousel-control-next').hide();        

        break;
        case 'gmcq_ss' :
        case 'gmcq_ssml' :
          
          if(d!="undefined")
            comp_el.attr('data-component-attempts',$.parseJSON(d).length);
          if(v.status == 1 && d!="undefined")
            $(document).setgmcq(comp_type.split('_')[1],comp_el,d);
          else
            $('.carousel-control-next').hide();
        break;

      }
      
    });

  },

  getActivityProgress: function (obj) {
    var self = this;
    var casestudy_id = $(".carousel-item").attr('data-cs-id');
    $.get( "/getactivity/"+casestudy_id+"/casestudy", function(data ) {
      var data = $.parseJSON(data);
      if(data.status.toString() !="false"){
        self.setActivityProgress(data)
      }else{
        var current_page  = $('.carousel-item.active');
        $(this).submitlog(current_page.find("[data-component-status]"),current_page);
      }  
    });
  },

});


