$.fn.extend({
    
    CustomL_h: function (init) {
        $(".h-ig").each(function(){
            var img_wd, img_ht, offset, _init = init, hgContainer = $(this), hgImg = $(this).find('img');

            function setPinPositions(init) {
                var div_width =  $(hgContainer).width();
                var off_per = ((offset.left)/div_width)*100;
                // console.log(init, img_wd, img_ht, offset, div_width, off_per)
                // console.log("left: " + offset.left + ", top: " + offset.top )
                // $(hgContainer).find('.a_div').css({'width':img_wd + 'px', 'height':img_ht + 'px'});
                var i = 1;
                if( init ) {
                  $(hgContainer).find('.hotgraphic-graphic-pin').each(function(){
                    $(this).css('top', $(this).attr('data-top-pos') + '%');
                  });
                }
            }
            img_wd = $(hgImg).width();
            img_ht = $(hgImg).height();
            offset = $(hgImg).position();
            setPinPositions(_init);
        })
    }
});
$.fn.hotgrapghics =  function(){
    //console.log("run hotgrapghics-js");
    var hg_widget = $(this);
    var hg_items = $(this).find(".hotgraphic-item");
    var hg_pins = hg_widget.find(".hotgraphic-graphic-pin");
    hg_pins.on("click", function(e){
        var pid = $(this).attr('data-pop-id');
        $(pid).closest('.rellax').addClass('no-transform');
        setTimeout(function() {
            $(document).CustomL_h();
        },50);
        onPinClicked(e, $(pid), pid, $(this).attr('data-id'), $(this).attr('data-modal-id'));
    });
    function onPinClicked( event, pid, showmodalid, id, mid ) {
        // Disable default link behaviour
        if(event) event.preventDefault();    
        /* Sets current pin as active. */
        hg_items.removeClass('active');
        pid.addClass('active');
        /* Set the pin as visited. */
        pid.addClass('visited');
        /* Selected pin's sub-items data-id */
        var $subitem_data_id = id;
        // console.log("subitem_data_id", $subitem_data_id);
        // console.log("modal", mid);
        /* Show correct series of subitems within the modal */
        $(mid).find('.hotgraphic-item.' + $subitem_data_id ).addClass('active');
        /* Remove active state on all pin modals. */
        $(mid).find('.modal-content').removeClass('active');
        /* Apply active state to selected pin's modal content. */
        // console.log("show modal", showmodalid);
        $(showmodalid+'-content').addClass('active');

        /* This is for two level hotgraphics. Hides all inner content, then shows the content for the
        * first of the subitem array. This is intended for when the first in the subitem array is
        * used for instructional text. */
        $(showmodalid+'-content .hotgraphic-item.active').find(".t-inner-popup").hide();
        $($(showmodalid+'-content .hotgraphic-item.active').find(".t-inner-popup")[0]).show();

        /* Show Modal */
        $(mid).modal('show');
        // console.log("show modal");
        /* Add listeners to inner hotspots pins */
        $(mid).find(".t-popup").on("click", function(e){
            e.preventDefault();
            $(this).addClass('visited');
            $(mid).find('.hotgraphic-item.active').find(".t-inner-popup").hide();
            $(mid).find('.hotgraphic-item.active').find(".t-inner-popup[data-t-popup=\""+$(this).attr('data-ip-pop')+"\"]").show();
        });
    }
    $('.hotgraphic-popup-done').on("click",function(e){
        $($(this).attr('data-pop-id')).parents('.rellax').removeClass('no-transform');
        var pid = $(this).attr('data-pop-id');
        closePopup(e,pid) 
    });
    $('.hotgraphic-popup-nav .back').on("click",function(e){
        setTimeout( function() {
            $(document).CustomL_h();
        },10);
        var pid = $(this).attr('data-pop-id');
        previousHotGraphic(e,pid) 
    });
    $('.hotgraphic-popup-nav .next').on("click",function(e){
        setTimeout( function() {
            $(document).CustomL_h();
        },10);
        var pid = $(this).attr('data-pop-id')  ;closePopup(e,pid) 
    });
    $('.hotgraphic-popup-nav .back').click(function(e){
        setTimeout(function(){$(document).CustomL_h();},10);
        var pid = $(this).attr('data-pop-id')  ;previousHotGraphic(e,pid) 
    });
    $('.hotgraphic-popup-nav .next').click(function(e){
        setTimeout(function(){$(document).CustomL_h();},10);
       var pid = $(this).attr('data-pop-id')  ;nextHotGraphic(e,pid)    
    }); 

    $(document).CustomL_h(true);
    $( window ).resize(function() {
        $(document).CustomL_h();
    }); 
}

$(".hotgraphic-widget").hotgrapghics();