$(document).ready(function(){


  $.fn.extend({
    regPageCustomHeight: function () {
      $('.cus-h').each(function(){
        var cus_h = ($(window).height()-$('header').height())/2 - $(this).height()/2;
        if(cus_h < 40){
           cus_h = 40; 
          
        }
        $(this).css("padding-bottom",cus_h+"px");
        $(this).css("padding-top",cus_h+"px");
      });
    }
  });
  if($('#accreditation_status').is(':checked')){
    $("#pf-fm").slideToggle().css('display','flex');
  }

  $( window ).resize(function() {
    $(document).regPageCustomHeight();
  });
  $(document).regPageCustomHeight();
  

  $("#accreditation_status").click(function(){
    $("#pf-fm").slideToggle().css('display','flex');
  })

  if($("#upgrade_sub").val()){
    
    var is_certification = $('.nd-cls:checked');
    var certification_length =  is_certification.length==0 ? "" : is_certification.length
    $("input[name='sub_check']").val(certification_length);
  }else{
    var is_certification = $('.cert-cls:checked');

    var certification_length =  is_certification.length==0 ? "" : is_certification.length
    $("input[name='cert_check']").val(certification_length);
  }
  var checkeboxes = $(".checkbox").find("[data-chkgrp]");
  $.each(checkeboxes, function(i,e){
    var $el  = $(e);
    var name = $el.attr('data-chkgrp');
    var $checkboxes = $el.closest('form').find('input[name="' + name + '"]:checked');
    var $dump_checkbox = $el.parents('.has-checkbox').find('.dump-checkbox');
   
    if($checkboxes.length==0){
      $dump_checkbox.attr("checked",false); 
    }else
      $dump_checkbox.attr("checked",true); 

    $checkboxes.not(':checked').trigger('input');
    $dump_checkbox.trigger('input');
  });


  $('[data-toggle="validator"]').validator().on('change.bs.validator', '[data-chkgrp]', function (e) {
    var $el  = $(e.target)
    var name = $el.data('chkgrp')
    var $checkboxes = $el.closest('form').find('input[name="' + name + '"]:checked')
    var $dump_checkbox = $el.parents('.has-checkbox').find('.dump-checkbox');
   
    if($checkboxes.length==0){
      $dump_checkbox.attr("checked",false); 
    }else
      $dump_checkbox.attr("checked",true); 

    $checkboxes.not(':checked').trigger('input')
    $dump_checkbox.trigger('input')
  });


  $.get("/assets/js/countries.json", function(data){
    //Added code for update profile page
    if($('#residence').val() != '') {
       $.each(data, function(index) {
          if($('#residence').val() == data[index].code) {
            $("#residence_typeahead").val(data[index].name);

          }
        });
       $("#userprofile" ).validator('validate');
    }
    //Registration code
    $("#residence_typeahead").typeahead({ source:data ,items :5,showHintOnFocus:false,fitToElement:true,displayKey: "item.name",updater: function(item) {
        $("#residence").val(item.code);
        return item;
      }
    });
  },'json');

  // if($(".file-preview-thumbnails tr").length){
  //   $('.dump-file').attr("checked",true).trigger('input');
  // }

  $('#qualifications').on('filereset change fileerror',function(e, data, msg){
    var $el  = $(e.target)
    var filecount = $(this).fileinput('getFilesCount');
    var $checkboxes = $el.closest('form').find('input[name="' + name + '"]:checked')
    var $dump_file = $('.dump-file');

    if(filecount==0){
      $dump_file.attr("data-error",$dump_file.attr('data-msg')); 
      $dump_file.attr("checked",false); 
    }else{
      $dump_file.attr("checked",true); 
    }

    if(msg != undefined){
      $dump_file.attr("data-error",msg); 
      $dump_file.attr("checked",false); 
    }
    $dump_file.trigger('input')
  });



  $('.v-browser-qualification').click(function(){
    $('#qualifications').trigger('click');
  });

  var btnCust = '<button type="button" class="btn btn-default" title="Add picture tags" ' + 
    'onclick="alert(\'Call your custom code here.\')">' +
    '<i class="fa  fa-tag"></i>' +
    '</button>'; 

  var teTagBef = '<tr   class="file-preview-frame {frameClass}" id="{previewId}" data-fileindex="{fileindex}"' +
        ' data-template="{template}"', teContent = '<td class="kv-file-content">\n';
  $.fn.fileinputThemes.explorer = {
      layoutTemplates: {
          preview: '<div class="file-preview {class}">\n' +
          '    {close}' +
          '    <div class="{dropClass}">\n' +
          '    <table class="table "><tbody class="file-preview-thumbnails">\n' +
          '    </tbody></table>\n' +
          '    <div class="clearfix"></div>' +
          '    <div class="file-preview-status text-center text-success"></div>\n' +
          '    <div class="kv-fileinput-error"></div>\n' +
          '    </div>\n' +
          '</div>',
          footer: '<td class="file-details-cell"><div class="explorer-caption" title="{caption}">{caption}</div> ' +
          '{progress}</td><td class="file-actions-cell">{actions}</td>',
          actions: '<div class="file-upload-indicator" title="{indicatorTitle}">{indicator}</div>\n' +
          '\n' +
          '<div class="file-actions">\n' +
          '    <div class="file-footer-buttons">\n' +
          '        {upload} <span class="kv-file-remove {removeClass}" ' +
              'title="remove" {dataUrl}{dataKey}><i class=\'fa fa-trash\'></i></span>{other} ' +
          '    </div>\n' +
          '</div>',
          zoomCache: '<tr style="display:none" class="kv-zoom-cache-theme"><td>' +
          '<table class="kv-zoom-cache">{zoomContent}</table></td></tr>'
      },
      previewMarkupTags: {
          tagBefore1: teTagBef + '>' + teContent,
          tagBefore2: teTagBef + ' title="{caption}" style="width:{width};height:{height};">' + teContent,
          tagAfter: '</td>\n{footer}</tr>\n'
      },
      previewSettings: {
          image: {height: "60px"},
          html: {width: "100px", height: "60px"},
          text: {width: "100px", height: "60px"},
          video: {width: "auto", height: "60px"},
          audio: {width: "auto", height: "60px"},
          flash: {width: "100%", height: "60px"},
          object: {width: "100%", height: "60px"},
          pdf: {width: "100px", height: "60px"},
          other: {width: "100%", height: "60px"}
      },
      frameClass: 'explorer-frame'
  };




  $("#qualifications").fileinput({
    theme: "explorer",
    overwriteInitial: false,
    showClose: false,
    showCaption: false,
    showCancel: false,
    showUpload: false,
    showRemove: false,
    showZoom: false,
    showDrag: false,
    footer: false,
    allowedFileExtensions: ['rtf', 'doc', 'docx', 'pdf', 'jpeg', 'png', 'tiff','jpg'],
    removeFromPreviewOnError:true,
    msgErrorClass: "has-file-error",
    removeClass: "remove-check",
    previewFileIcon: '<i class="fa fa-file"></i>',
    initialPreview: [],
    initialPreviewAsData: false, // defaults markup  
    initialPreviewConfig: [],
    uploadExtraData: {
        img_key: "1000",
        img_keywords: "happy, nature",
    },
    preferIconicPreview: true, // this will force thumbnails to display icons for following file extensions
    previewFileIconSettings: { // configure your icon file extensions
        'doc': '<i class="fa fa-file-word-o"></i>',
        'xls': '<i class="fa fa-file-excel-o "></i>',
        'ppt': '<i class="fa fa-file-powerpoint-o "></i>',
        'pdf': '<i class="fa fa-file-pdf-o "></i>',
        'zip': '<i class="fa fa-file-archive-o "></i>',
        'htm': '<i class="fa fa-file-code-o "></i>',
        'txt': '<i class="fa fa-file-text-o "></i>',
        'mov': '<i class="fa fa-file-movie-o "></i>',
        'mp3': '<i class="fa fa-file-audio-o "></i>',
        'jpg': '<i class="fa fa-file-photo-o"></i>', 
        'gif': '<i class="fa fa-file-photo-o "></i>', 
        'png': '<i class="fa fa-file-photo-o "></i>'    
    },
    previewFileExtSettings: { // configure the logic for determining icon file extensions
      'doc': function(ext) {
          return ext.match(/(doc|docx)$/i);
      },
      'xls': function(ext) {
          return ext.match(/(xls|xlsx)$/i);
      },
      'ppt': function(ext) {
          return ext.match(/(ppt|pptx)$/i);
      },
      'zip': function(ext) {
          return ext.match(/(zip|rar|tar|gzip|gz|7z)$/i);
      },
      'htm': function(ext) {
          return ext.match(/(htm|html)$/i);
      },
      'txt': function(ext) {
          return ext.match(/(txt|ini|csv|java|php|js|css)$/i);
      },
       
    }
  });

  $("#profilePicture").on('fileerror',function(e,data,msg){
    var $dump_img = $('.dump-img');
    if(msg != undefined){
        $dump_img.attr("required",true); 
        $dump_img.attr("data-error",msg); 
        $dump_img.attr("checked",false); 
      }
    $dump_img.trigger('input')
    $('.fileinput-remove-button').trigger('click');
    $dump_img.attr("required",false);
  });




var fileinput = {
    overwriteInitial: true,
    maxFileSize: 1500,
    showClose: false,
    showCaption: false,
    showCancel: false,
    showUpload: false,
    footer: false,
    browseLabel: '',
    removeLabel: '',

    msgSizeTooSmall: 'File "{name}" ({size} KB) is too small and must be larger than {minSize} KB.',
        msgSizeTooLarge: 'File "{name}" ({size} KB) exceeds maximum allowed upload size of {maxSize} KB.',
        msgFilesTooLess: 'You must select at least {n} {files} to upload.',
        msgFilesTooMany: 'Number of files selected for upload ({n}) exceeds maximum allowed limit of {m}.',
    browseIcon: '<i class="fa fa-camera"></i>',
    browseClass: 'btn btn-link btn-custom-avatar',
    removeIcon: 'Remove',
    removeClass: 'btn btn-link btn-link-custom',
    removeTitle: 'Cancel or reset changes',
    // elErrorContainer: '#kv-avatar-errors-1',
    msgErrorClass: 'alert alert-block alert-danger',
    //defaultPreviewContent: '<img src="/images//profile/default-profile.svg" alt="{{ $member->username }}" width="160px">',
    //defaultPreviewContent: '<img src="/images//profile/default-profile.svg" alt="{{ $member->username }}" width="160px">',
    // layoutTemplates: {main2: '{preview} ' +  btnCust + ' {remove} {browse}'},
    allowedFileExtensions: ["jpg", "png", "gif","jpeg"],
    removeFromPreviewOnError:true,
    layoutTemplates:{footer: ''}
  }
  var existingImg = $("#profilePicture_").val();
  if(existingImg){
    fileinput.defaultPreviewContent = '<img src="'+existingImg+'" alt="Your Avatar" width="160px">'
  }
  else{
    fileinput.defaultPreviewContent = '<img src="/images//profile/default-profile.svg" alt="Your Avatar" width="160px">'
  }
  $("#profilePicture").fileinput(fileinput);    
    var totalAmount = 0;
    equipment_form();
     practical_form();
        var is_certification = $('.cert-cls:checked');

        var certification_length =  is_certification.length==0 ? "" : is_certification.length
        $("input[name='cert_check']").val(certification_length);

        var is_practical_selected = $('.pract-cls:checked');
        if(is_practical_selected.length>0) {
          $('.pract-cls-hd').find('input').prop('checked', true);
        }

        var c_len = $('.category_product:checkbox:checked').length

        if(c_len>0)
          $("#choose_product" ).validator('validate');
        




        calculate_total_amount();



    $(".category_product").on('click', function(e)
    {

      if($(this).is(':checked')){
          equipment_form();
          $el_c = $(this).parents('.checkbox');
          $el_b = $(this).parents('.card-block');
          console.log($el_c.index());
          if($(this).hasClass('nd-cls')) {
            $el_b.find('.checkbox').find('input').html();
            $el_b.find('.checkbox').find('input').prop('checked',false);
            $(this).prop('checked',true)
            $(this).removeAttr('checked',true)
          }
          if($("#upgrade_sub").val()){
            var is_certification = $('.nd-cls:checked');
            var certification_length =  is_certification.length==0 ? "" : is_certification.length
            $("input[name='sub_check']").val(certification_length);
          }else{
            var is_certification = $('.cert-cls:checked');

            var certification_length =  is_certification.length==0 ? "" : is_certification.length
            $("input[name='cert_check']").val(certification_length);
          }
          practical_form();
          calculate_total_amount();

          $("#choose_product,#purchasevouchers" ).validator('validate');
      } else {
          equipment_form()
          if($("#upgrade_sub").val()){

            var is_certification = $('.nd-cls:checked');
            var certification_length =  is_certification.length==0 ? "" : is_certification.length
            $("input[name='sub_check']").val(certification_length);
          }else{
            var is_certification = $('.cert-cls:checked');

            var certification_length =  is_certification.length==0 ? "" : is_certification.length
            $("input[name='cert_check']").val(certification_length);
          }
          practical_form();
          calculate_total_amount();
          $("#choose_product,#purchasevouchers" ).validator('validate');
        }

    });
    
    $( "#qty" ).keyup(function() {
      calculate_total_amount();
    });
    $( "#inputPassword" ).keyup(function() {
      $("#password_confirmation").prop('required',true);
      $("#currentPassword").prop('required',true);
    });
    

    voucher_check();
    function practical_form(){

        var is_practical = $('.pract-cls:checked'); 
        var is_equipment = $('.ep-cls:checked');


        if(is_practical.length>0 && is_equipment.length==0){
            $('.pract-cls-hd').slideDown().css('display', 'flex');
            $('.pract-cls-hd').find('input').attr('required',true);
        }else{
            $('.pract-cls-hd').slideUp();
            $('.pract-cls-hd').find('input').attr('required',false)
            
        } 

    }
    function equipment_form(){
        var is_equipment = $('.ep-cls:checked');          
        if(is_equipment.length>0){
            $('.eq-cls-hd').slideDown().css('display', 'flex');
            $('.eq-cls-hd').find('input').attr('required',true);
        }else{
            $('.eq-cls-hd').slideUp();
            $('.eq-cls-hd').find('input').attr('required',false)
            
        }    
    }

    function updateTotalAmount(amount, cost, gst, status, residence)
    {

        var qty = 1;
        if($('#qty').val()){
          qty = $('#qty').val();
        }
        
        //var productCost = cost;
        var afterDiscCost = applyDisc(qty,cost);
        var totalCost = afterDiscCost * qty;
        
        if(status){
            amount += totalCost;
        } else {
            amount -= totalCost;
        }
        
        return round(amount, 2);
    }

    function calculateGstPrise(cost, gst)
    {
        var gstAmount = (gst / 100) * cost;

        return gstAmount;
    }

    /**
      * Round the decimal value 
      *
      * @return Integer
      */
    function round(value, precision)
    {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }

    function applyDisc(qty, cost)
    {
      var afterDiscCost = cost;
      var discountPct = 0;
          $.ajax({
                headers: { 'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content') },
                type: 'get',
                url: '/getDiscount',
                data: { id: qty },
                cache: false,
                async:false,
                success:function(response){
                    discountPct = response;
                    afterDiscCost = cost - (cost*discountPct/100);
                }
          });
      return round(afterDiscCost,2);
     
    }

    function calculate_total_amount()
    {
      var totalAmount = 0;
      if ($(".category_product:checkbox:checked").length >0 ) {
        $(".category_product:checkbox:checked").each(function(e){
           var cost = parseFloat($(this).attr('productCost'));
            var gst = parseFloat($(this).attr('productGst'));
            var residence = $(this).attr('residence');

            totalAmount = updateTotalAmount(totalAmount, cost, gst, true, residence);
            if(totalAmount==0){
                totalAmount_ = 0;
            }
            else {
                if(residence == 'AU') {
                    var gstCost = calculateGstPrise(totalAmount, gst);
                } else {
                    var gstCost = 0;
                }

                totalAmount_ = totalAmount + gstCost;
            }
                
            $("input[name='total_amount']").val(totalAmount_);
            $("#total-amount").html(totalAmount_.toFixed(2).replace(/./g, function(c, i, a) {
                return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
            }));
            // if($('#qty').val() != ''){
            //   qty = ($('#qty').val() == '') ? 1 : $('#qty').val();
            //   var sub_totalAmount_ = totalAmount_/qty;
            //   $("#subtotal-amount").html(sub_totalAmount_);
            // }else{
            //   var sub_totalAmount_ = totalAmount_;
            //   $("#subtotal-amount").html(totalAmount_);
            // }
        })
      } else {
          $("input[name='total_amount']").val(totalAmount);
          // $("#total-amount").html(totalAmount);
          $("#total-amount").html(totalAmount.toFixed(2).replace(/./g, function(c, i, a) {
                return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
            }));
          //$("#subtotal-amount").html(totalAmount);
      }
    }
    function voucher_check(){
      $(".nd-cls").on('click', function(e){
           var is_certification = $('.nd-cls:checked');
           if(is_certification.length>0){
             var certification_length = is_certification.length
             $("input[name='voucher_cert_check']").val(certification_length);
           }
           else{
             $("input[name='voucher_cert_check']").val('');
           }
           $("#purchasevouchers" ).validator('validate');
      });
    }
    $(".upgrd-cls").on('click', function(e)
    {
      var is_upgrade = $('.upgrd-cls:checked'); 
      var is_certification = $('.cert-cls:checked'); 
      if(is_upgrade.length>0){
          $("input[name='cert_check']").val(1);
          $('.upgrd-cls-hd').slideDown().css('display', 'flex');
          $('.upgrd-cls-hd').find('input').attr('required',true);
            
            var checkboxinpouts = $(".cert-cls").prop('checked',false);
            calculate_total_amount();
            $('.pract-cls-hd').slideUp();
            $('.pract-cls-hd').find('input').attr('required',false)
      }else{
        $("input[name='cert_check']").val('');
          $('.upgrd-cls-hd').slideUp();
          $('.upgrd-cls-hd').find('input').attr('required',false)
          
      }
    });
    $(".cert-cls").on('click', function(e)
    {
      var is_certification = $('.cert-cls:checked'); 
      if(is_certification.length>0){
            var checkboxinpouts = $(".upgrd-cls").prop('checked',false);
            calculate_total_amount();
            $('.upgrd-cls-hd').slideUp();
            $('.upgrd-cls-hd').find('input').attr('required',false)
      }
    });

});