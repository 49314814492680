$(document).ready(function(){

// var slideIndex = 1;
// showDivs(slideIndex);

// $(".pull-left.slider_arrows").on("click", function(){
// 	showDivs(slideIndex += -1);
// });
// $(".pull-right.slider_arrows").on("click", function(){
// 	showDivs(slideIndex += 1);
// });

// $("._dot").on("click", function(){
// 	slideIndex = $(this).index()+1;
// 	showDivs(slideIndex);
// });

// function showDivs(n) {
//   var i;
//   var x = $(".mySlides");
//   var y = $(".slideContent");
//   var dots = $("._dot");

//   if (n > x.length) {slideIndex = 1}    
//   if (n < 1) {slideIndex = x.length}
//   if(n==1){slideIndex = 1}

//   $(".mySlides").hide();
//   $(".slideContent").hide();
//   $("._dot").removeClass("w3-white");

//   $(x[slideIndex-1]).show();  
//   $(y[slideIndex-1]).show();
//   $(dots[slideIndex-1]).addClass("w3-white");
// }
 
(function( $ ) {
 
    $.fn.simple_slider = function() {

        var slider = this;
        $.each($(slider), function(i,e){
        	//console.log(i);
        	var current_slider = $(e);
        	var slider_menu = $(current_slider).find(".slider_menu");
	        var left = $(slider_menu).find("._left");
	        var right = $(slider_menu).find("._right");

	        var imgslides = $(slider_menu).find(".mySlides");
	        var contentslides = $(current_slider).find(".slider_content").find(".slideContent");
	        var dots = $(slider_menu).find("._dot");

	        var slideIndex = 1;
			showDivs(slideIndex);

	        $(left).on("click", function(){
	        	showDivs(slideIndex += -1);	
	        });
	        $(right).on("click", function(){
	        	showDivs(slideIndex += 1);
	        });
	        $(dots).on("click", function(){
	        	slideIndex = $(this).index()+1;
	        	showDivs(slideIndex);
	        });

	        function showDivs(n) {
			  var i;
			  if (n > imgslides.length) {slideIndex = 1}    
			  if (n < 1) {slideIndex = imgslides.length}
			  if(n==1){slideIndex = 1}

			  $(imgslides).hide();
			  $(contentslides).hide();
			  $(dots).removeClass("w3-white");

			  $(imgslides[slideIndex-1]).show();  
			  $(contentslides[slideIndex-1]).show();
			  $(dots[slideIndex-1]).addClass("w3-white");
			  // setTimeout(function(){
			  // 	slideIndex++;
			  // 	console.log("auto-next",slideIndex);
			  // 	showDivs(slideIndex)
			  // },3000);
			}
        });

    };
 
}( jQuery ));

$( ".simple_slider" ).simple_slider();

});  