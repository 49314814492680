$(document).ready(function(){
  'use strict';

 
var page = 1;
var current_page = 1;
var total_page = 0;
var is_ajax_fire = 0;

if(typeof url !== "undefined") 
    manageData();

/* manage data list */
function manageData() {
    $.ajax({
        dataType: 'json',
        url: url,
        data: {page:page}
    }).done(function(data){

    	total_page = data.last_page;
    	current_page = data.current_page;

    	// $('#pagination').twbsPagination({
	    //     totalPages: total_page,
	    //     visiblePages: current_page,
	    //     onPageClick: function (event, pageL) {
	    //     	page = pageL;
     //            if(is_ajax_fire != 0){
	    //     	  getPageData();
     //            }
	    //     }
	    // });

    	manageRow(data.data);
        is_ajax_fire = 1;
    });
}

$.ajaxSetup({
    headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
});

/* Get Page Data*/
function getPageData() {
	$.ajax({
    	dataType: 'json',
    	url: url,
    	data: {page:page}
	}).done(function(data){
		manageRow(data.data);
	});
}

/* Add new Item table row */
function manageRow(data) {
	var	rows = '';
    var i =1;
	$.each( data, function( key, value ) {
        if(value.document != null){

            
            var ext = value.document.split('.').pop();
            var fa_cls = ext == 'pdf' ? 'fa-file-pdf-o c-pdf' : 'fa-file-word-o c-doc';
        }else{
            var fa_cls = 'fa-link'
        }
        console.log(value);
        var doc = value.document == null ? value.links : 'https://cel-au-app.s3.amazonaws.com/'+value.document
        rows = rows + '<li  class="bt-1 list-group-item justify-content-between">  <a href="'+doc+'" target="_blank"><i class="fa-stack fa '+fa_cls+'"></i><span class="c-res">'+value.name+'</span></a></li>';
	  	i++;
	});

    if(!rows)
        rows = '<li class="list-group-item justify-content-center"> No record found</li>'

	$(".rs-list").html(rows);
}
});