$(window).on("load", function() {
  setTimeout(function(){
    $(".app-body").css("opacity","1");
    $('#overlay').remove();
    },3000);
});

$(document).ready(function(){
  // $('body').append('<div id="overlay"><div class="loader_"></div></div>');

  $('button.s-t').on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: $($(this).attr('data-id')).offset().top}, 800, 'linear');
  });

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  $(".progress-donut span").peity("donut");
  var flipContainer = $('.flipster'),
    flipItemContainer = flipContainer.find('.flip-items'),
    flipItem = flipContainer.find('li'),
    flipsterInitialised = false;

  var init_carousel = function () {
    flipContainer.flipster({
      itemContainer: flipItemContainer,
      itemSelector: flipItem,
      loop: 2,
      start: 0,
      style: 'infinite-carousel',
      spacing: -0.6,
      //nav: 'after',
      nav: 'after',
      buttons: true,
      autoplay: 5000
    });
    $('.flipster__item__content').click(function(){
      var el = $(this).parent();
      if(el.hasClass('flipster__item--current')){
        window.location.href = "/casestudy/"+el.attr('data-id');
      }
    });
    flipsterInitialised = true;
  }

  if(window.innerWidth >= 768) {
    init_carousel();
  } else {
    $('.cs-carousel').css('display', 'none');
  }
  $(window).on('resize', function () {
    if(window.innerWidth < 768) {
      $('.cs-carousel').css({'height': 0, 'margin': '0 -15px'});
    } else {
      $('.cs-carousel').css({'display': 'block','height': 'auto', 'margin': '10px -15px'});
      if(!flipsterInitialised) {
         init_carousel();
      }
    }    
  });
  $('body').on('hidden.bs.modal', '.modal', function () {
    $('video').trigger('pause');
  });
  $('#carousel-main').css('visibility','visible');
  // Configure/customize these variables.
  var showChar = 200;  // How many characters are shown by default
  var ellipsestext = "...";
  var moretext = "Read more";
  var lesstext = "Read less";

  $('.more').each(function() {
    var content = $(this).html();
    var datachar = $(this).attr('data-char-count');
      if(datachar)
        showChar = datachar;
      console.log(content.length)
      console.log(showChar)
    if(content.length > showChar) {
      var c = content.substr(0, showChar);
      var h = content.substr(showChar, content.length - showChar);
      var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent" style="display:inline"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink btn btn-link btn-sm">' + moretext + '</a></span>';
      $(this).html(html);
    }
  });
 
  $(".morelink").click(function(){
    if($(this).hasClass("less")) {
      $(this).removeClass("less");
      $(this).html(moretext);
    } else {
      $(this).addClass("less");
      $(this).html(lesstext);
    }
    $(this).parent().prev().fadeToggle();
    $(this).prev().slideToggle('fast', function() {
    if ($(this).is(':visible'))
        $(this).css('display','inline');
    });
    return false;
  });

  // $('.flipster__item--current').click(function(){
  //   window.location.href = "/casestudy";
  // });

})  