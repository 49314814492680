

$(document).ready(function(){


  $.fn.setgraphic_slider = function (type,el,data) {


    el.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+data+"'/>")

      var max_attempts = parseInt(el.attr('data-max-attempts'));
    var p_d = JSON.parse(data);
    var last_ans = p_d.slice(-1)[0];

    
    if(p_d.length >= max_attempts){
      el.find('.q-feedback').find('button').hide();
         $('.carousel-control-next').show();
    }else{
      $('.carousel-control-next').hide();  
    }

    
    el.find('.q-feedback.'+last_ans.answer).show();

    var lf_val =last_ans.value[0];
    var rt_val =last_ans.value[1];

    el.find('.g-left').find('input').eq(lf_val).attr('checked','checked');
    el.find('.g-right').find('input').eq(rt_val).attr('checked','checked');
    
           
    el.find('.g-left,.g-right').addClass('disabled').find('.ol').show();

    if(last_ans.answer != "correct"){
    el.find('.q-content').hide();
    }else{
      el.find('.gs-b').hide();
      el.find('.slider_controls').hide();
      $('.carousel-control-next').show();
       el.attr('data-component-attempts',el.attr('data-max-attempts'));

    }
  }
  



  var $li = $('.slider-right,.slider-left');
  $li.each(function(k,v){
   var $lis = $(this).find('img'); 
   // var $lis = $(this).find('img'); 
    var pos =  $(this).attr('data-position');
    rand=Math.floor(Math.random() * 3); // 
    var gsl = $(this).parents('.g-sl');
    gsl.find('.g-'+pos).find('input').eq(rand).attr('checked','checked');
    $lis.eq(rand).removeClass('hide').addClass("show");
  });
 
$('.g-radio').radiosToSlider({
    animation: true,
    fitContainer:false
  });

  

  $('.g-radio .slider-level').click(function(){
    var gls = $(this).parents('.g-sl');
    var ps = $(this).parent().attr('data-position');
    var val = $(this).attr('data-value');
    var sl_or = gls.find('.slider-'+ps);
    sl_or.find('img').hide();
    sl_or.find('img').eq(val).fadeIn();

  })


  $('.gs-b').click(function(){
    var gsl = $(this).parents('.g-sl');
    var cls = "incorrect";
    var lf_el = gsl.find('.g-left').find('input:checked');
    var lf = lf_el.attr('data-selected');
    var rt_el = gsl.find('.g-right').find('input:checked');
    var rt = rt_el.attr('data-selected');
    var score = 0;
    var component = $(this).closest("[data-component-status]");
    var attempts = parseInt(component.attr('data-component-attempts'));
    var max_attempts = parseInt(component.attr('data-max-attempts'));
    var current_page = $(this).closest("[data-page-status]");
    var am_ans = gsl.find('.attempts');

   

    var ans_g = [lf_el.index()-1,rt_el.index()-1];
    if(lf ==true && rt==true){
          cls = "correct";
          score = 1;
    }

    $('.b-ws').trigger('click');

    am_val = am_ans.val();
    if(!am_val)
      am_val = [{value:ans_g,answer:cls,score:score}];
    else{
      am_val= JSON.parse(am_val);
      am_val.push({value:ans_g,answer:cls,score:score});
    }
    am_ans.remove();
    gsl.find('.q-content').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+JSON.stringify(am_val)+"'/>")
    component['attempt'] = am_val;

    component.attr('data-component-status',1);
    $(this).submitlog(component,current_page, cls);


    component.attr('data-component-attempts',attempts+1);
   

    if(attempts+1 >=max_attempts){
      gsl.find('.q-feedback.'+cls).find('button').hide();
    }

    if(cls == "incorrect")
      gsl.find('.q-content').hide(); 
    else
      component.attr('data-component-attempts',component.attr('data-max-attempts'))


    gsl.find('.q-feedback,.gs-b').hide(); 
     $('html, body').animate({ scrollTop: gsl.offset().top-50}, 800, 'linear');

    gsl.find('.q-feedback.'+cls).slideDown('slow');
    gsl.find('.g-left,.g-right').addClass('disabled').find('.ol').show();
  });

  $('.gs-t-b').click(function(){
    var gsl = $(this).parents('.g-sl');

    gsl.find('.q-feedback').hide(); 
    gsl.find('.gs-b').show(); 
    $('html, body').animate({ scrollTop: gsl.offset().top-50}, 800, 'linear');

    gsl.find('.q-content').slideDown('slow');
    gsl.find('.g-left,.g-right').removeClass('disabled').find('.ol').hide();


  });


  
});