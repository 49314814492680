jQuery.fn.extend({
    
    setActivity: function (obj) {
      //console.log(obj.find('section'));
      // {case_study:1, case_study_status: "completed", stage:1, stage_status: "completed", page:1, page_status: "completed", component:1, component_status: "read/watched", block: 1, component_type: "mcq", other_infromation_related_to_component}
    },

    getComponents: function(obj){
      var components = $(obj).find('[data-component-status]');
      var compWithTypeStatus = {};
      var compsTypeStatus = [];
      $.each(components, function(i,compEle){
        compsTypeStatus.push({
          component:compEle,
          type:$(compEle).attr("data-component-type")
        });
      });
      return compsTypeStatus;
    },

    checkStatus: function(obj){
      var page = $(obj);
      var block = $(obj).find("[data-block-status]");

      var pageStageIndex = $(obj).attr("data-stage-index");
      var blocksOfSameStage = $("[data-stage-index="+pageStageIndex+"]");
      var blocksLen = blocksOfSameStage.length;
      var blockStatusCounter = 0;

      var components = $(obj).find("[data-component-status]");
      var componentsLen = components.length;
      var compStatusCounter = 0;
      //if page status


      if(!$(page).attr("data-page-status")){
          //check for mcq_ss status
        if(!$(block).attr("data-block-status")){
          //check for component status
          $.each($(components),function(i,component){
            // alert($(component).attr("data-component-status"));
            if(!$(component).attr("data-component-status")){
              $('.carousel-control-next').hide();
              console.log('hide next')
              return false;
            } else {
              compStatusCounter++;
              if(compStatusCounter == componentsLen){
                setBlockActive();
                $('.carousel-control-next').fadeIn('slow');
                console.log('show next')
              }
              else{
                $('.carousel-control-next').hide();
                console.log('hide next')
              }
            }
          });
        } else {
          $('.carousel-control-next').fadeIn('slow');
          console.log('show next')
          return true;
        }
      } else {
        $('.carousel-control-next').fadeIn('slow');
        console.log('show next')
        return true;
      }
      function setBlockActive(){
        $(block).attr("data-block-status", 1);
        var blockCounter = 0;
        $.each(blocksOfSameStage, function(i,stage){
          if($(stage).find("[data-block-status]").attr("data-block-status")){
            blockCounter++;
          }
          if(blocksLen==blockCounter){
            setPagesActive($(stage).attr("data-stage-index"));  
          }
        });
      }
      function setPagesActive(stageIndex){
        var slides = $("[data-stage-index="+stageIndex+"]");
        $.each(slides, function(i,slide){
          $(slide).attr("data-page-status",1);
        })
        var sliderLi = $("[data-stage-status]");
        $(sliderLi[stageIndex]).attr("data-stage-status", 1);
      }
    },

    submitlog: function(component,current_page,status){

        var el_stage = $('.carousel-indicators-p li[data-stageid='+current_page.attr("data-stage-id")+']');
        
        var el_block =  $(component).parents('section');
  
        

        if($(component).attr('data-component-attempts') >0){
          $(component).attr("data-component-status",1);
        }
        
        // console.log("component", component);
        // console.log("component", component.attempt);
        // console.log("component", JSON.stringify(component.attempt));

        /*Set Status*/
        var cmp = component;
        var c_p = current_page;
        // var prev_index = current_page.index()-1
        // var previous_page = $('.carousel-item').eq(prev_index);

       
        var c_ls = $(this).find('.carousel-item');
        var pg_id = c_p.attr('data-page-id');
        var pg_obj = $('div[data-page-id='+parseInt(pg_id)+']');

        pg_obj.each(function(k,v){
          var cm_status = $(this).find('div[data-component-status=0]').attr('data-component-status');
          if(typeof cm_status == "undefined"){
            $(this).attr('data-page-status',1); 
          }

        });

        var stg_id = c_p.attr('data-stage-id');

        var pg_status = $('div[data-stage-id='+parseInt(stg_id)+'][data-page-status=0]').attr('data-page-status');
      
        if(typeof pg_status == "undefined"){
            var stg_idx = c_p.attr('data-stage-index');
            $('.carousel-indicators-p li').eq(stg_idx).attr('data-stage-status',1)
          }
         var stg_status = $('.carousel-indicators-p li[data-stage-status=0]').attr('data-stage-status');

        if(typeof stg_status == "undefined"){
             $('#pageCarousel').attr('data-cs-status',1);

        }
        
         var current_page = $('.carousel-item.active');
          var c_length = $("#pageCarousel").find('.carousel-item').length;
          var is_last = current_page.index() == (c_length-1) ? 1 : 0 
         
         if(is_last){
            $('<div id="overlay-50"><div class="loader_"></div></div>').insertAfter('.cs-dashboard-charts');
            
         }


   
        setTimeout(function(){
          var datastage_status =  el_stage.attr('data-stage-status') == ''? 0:el_stage.attr('data-stage-status');
          var cs_status =  $('#pageCarousel').attr('data-cs-status') == ''? 0: $('#pageCarousel').attr('data-cs-status');

          // console.log("casestudy status:"+cs_status+"---stage status:"+datastage_status+"---component id:"+$(component).attr('data-component-id')+"---component status:"+$(component).attr('data-component-status'));
          $.ajax({
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content') },
            method: "POST",
            url: "/logactivity/",
            data: {
              "activity_type":"casestudy",
              "id": current_page.attr("data-cs-id"),
              "status": cs_status,
              "casestudy_duration": 0,
              "attempt":1,
              "stage": current_page.attr("data-stage-id"),
              "stage_duration": 0,
              "stage_status": datastage_status,
              "page": current_page.attr("data-page-id"),
              "page_status": current_page.attr("data-page-status"),
              "block": el_block.attr('data-block-id'),
              "block_status" : el_block.attr('data-block-status'),
              "component": $(component).attr('data-component-id'),
              "component_status": $(component).attr('data-component-status'),
              "component_type": $(component).attr('data-component-type'),
              "component_info": "",
              "component_max_attempts": $(component).attr('data-max-attempts'),
              "component_duration": 0,
              "component_attempts":encodeURIComponent(JSON.stringify(component.attempt)),
              "is_last":is_last
            }
              
          }).done(function( res ) {
            if(is_last){
              $('.btn-group-full').html(($(res).find('.btn-group-full').html()));
              $('.cs-dashboard-charts').html(($(res).find('.cs-dashboard-charts').html()));
              $(".chart-donut span").peity("donut");

              setTimeout(function(){
                   $('#overlay-50').remove(); 
                   $('.cs-dashboard-charts').fadeIn();
                 },1000)
           
            }
            console.log('STATUS IS ' + $(component).attr("data-component-status") + ' ' + status)
            $(component).attr("data-component-status",1);
            if(status != 'correct' && $(component).attr('data-component-attempts') < $(component).attr('data-max-attempts')) {
              $(component).attr("data-component-status",0);
              $('.carousel-control-next').hide();
            } else if(current_page.index() != c_length-1) {
              $('.carousel-control-next').show();
            }             
              // $({}).checkStatus(current_page);
          });

        },100);  
    }
});


