$(document).ready(function(){



  $.fn.setgmcq = function (type,el,data) {


    el.find('.qs-text').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+data+"'/>")
    
    var p_d = JSON.parse(data);
    var last_ans = p_d.slice(-1)[0];
    var ans = el.find('#ans').val();

   

    el.find('.qs-text').hide();

    if(type == "ss"){
      el.find('.qs-text-bn').hide();
      var  opts =  el.find('.row.my-1 .qs');
      el.find('.my-2').eq(last_ans.value).addClass('selected');
      el.find('.qs .ol').show();
      el.find('.qs .ol .fa').hide();
      el.find('.my-2').eq(last_ans.value).find('.qs .ol .fa').show();
      opts.each(function(k,v){
        $(this).find('.fa').addClass('fa-check-circle');
        var c_idx = $(this).attr('data-index');
        $(this).unbind('click').css('cursor','default');

        if(c_idx!=ans){
              $(this).find('.fa').removeClass('fa-check-circle').addClass('fa-times-circle');
            }
    });


      // console.log(last_ans.answer)

      if(last_ans.answer ==true)
        el.find('.qs-fb.correct').show();
      else  
        el.find('.qs-fb.incorrect').show();
    }else{
        // var hm = "";
        // var ls = el.find('li');


        // ls.each(function(k,v){
        //   var tv = $(this).attr('data-value');
        //   if(tv=='true'){
        //     hm = hm+"<li>"+$(this).find('.tx').html()+"</li>";
        //   }

        // });

        // el.find('.q-feedback.pcorrect .lst').html(hm);
        // el.find('.q-feedback.'+last_ans.answer).slideDown('slow');
    }
           

  },     
  

  $('.single-choice.cs-cm-mcq-graphic .submit').click(function(e){

    var mcls = $(this).parents('.cs-cm-mcq-graphic');
    
    $('.b-ws').trigger('click');



    var ans = mcls.find('#ans').val();
    var idx = false;
    var score = 0;
    var index = mcls.find('.selected').find('.qs').attr('data-index');
    var am_ans = mcls.find('.attempts');
 
    var attempts = parseInt(mcls.attr('data-component-attempts'));
    mcls.attr('data-component-attempts',attempts+1);


    if(typeof index === "undefined"){

      return false;
    }

  
   

    var  qstext =  mcls.find('.qs-text');
   
    var  opts =  mcls.find('.row.my-1 .qs');

    mcls.find('.qs .ol').show();
    mcls.find('.qs .ol .fa').hide();
    mcls.find('.selected').find('.qs .ol .fa').show();
    mcls.find('.opts .ol').show();
    mcls.find('.opts .ol-g').show();


    var a_cs= mcls.find('.selected').length;
    if(!a_cs)
      return false;

    opts.each(function(k,v){
    $(this).find('.fa').addClass('fa-check-circle');

      var c_idx = $(this).attr('data-index');
      $(this).unbind('click').css('cursor','default');
      if(c_idx!=ans)
        $(this).find('.fa').removeClass('fa-check-circle').addClass('fa-times-circle');


    

    });


    var cls =  "incorrect"
    if(index == ans) {
      cls ="correct"
      idx = true;
      score = 1;

    }
    
   
    var  qsfb =  mcls.find('.qs-fb.'+cls);


    qstext.fadeOut(function(){qsfb.fadeIn();
    })
      mcls.find('.qs-text-bn').fadeOut();
    var component = $(this).closest("[data-component-status]");
    var current_page = $(this).closest("[data-page-status]");
    
    am_val = am_ans.val();
    if(!am_val)
      am_val = [{value:index,answer:idx,score:score}];
    else{
      am_val= JSON.parse(am_val);
      am_val.push({value:index,answer:idx,score:score});
    }
    am_ans.remove();

    mcls.find('.my-1').append("<input type=\"hidden\" class=\"attempts\" name=\"attempts\" value='"+JSON.stringify(am_val)+"'/>")
    component['attempt'] = am_val;
    $(this).submitlog(component,current_page,cls);

    var mcls_sec =    $(this).parents('section');
    setTimeout(function(){
      $(mcls_sec).animate({ scrollTop: mcls_sec.offset().top - 100}, 800, 'linear');
    },200)   
    

  });

  $('.single-choice.cs-cm-mcq-graphic .qs').click(function(e){

     $(this).find('.fa').removeClass('fa-check-circle'); 
     $(this).parent().addClass('selected').siblings().removeClass('selected');
     $(this).parents('.cs-cm-mcq-graphic').find('#submit').removeClass('disabled');
     // var ans = $(this).parents('.cs-cm-mcq-graphic').find('#ans');
     // var index = $(this).attr('data-index');
     
     
  });  
  


  $('.cs-cm-mcq-graphic #submitg').click(function(e){



    var a_cs= $('.opts .active').length;
    if( a_cs != $('.opts').length)
      return false;
    $('.cs-cm-mcq-graphic #submitg').hide();
    // $('.qs-text').fadeOut(function(){$('.qs-fb').fadeIn();})
    // $('.qs-text-bn').fadeOut(function(){$('.qs-fb-bn').fadeIn(); $('.qs-text-bn button').text('SHOW FEEDBACK');})
    $('.qs .ol,.opts .ol ,.opts .ol-g').show();
    var a_cs = $('.opts .active');
    var a_cs_ans = $('#ans').val().split(',');
		$('.opts label').addClass('btn-disabled'); 
    
      a_cs.each(function(k,v){
       var g_ans = $(this).find('input').val().toLowerCase();
        if(a_cs_ans[k].toLowerCase() != g_ans)
          $(this).parent().parent().prev().find('.fa').removeClass('fa-check-circle').addClass('fa-times-circle');
      });
    
  });

  $('.opts label').click(function(e){
    setTimeout(function(){     
      var a_cs= $('.opts .active').length;
      if( a_cs == $('.opts').length & a_cs >0 )
         $('.cs-cm-mcq-graphic #submit').removeClass('disabled'); 
      }, 10)
	});

	$('.qs-fb-bn').click(function(e){
    $('.qs-fb').fadeOut(function(){$('.qs-text').fadeIn();$('.opts label').addClass('btn-disabled');})
    $('.qs-fb-bn').fadeOut(function(){$('.qs-text-bn').fadeIn();})
  })

});