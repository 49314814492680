$(document).ready(function(){
	$(".cs-cm-video, .cs-sm-audio").transcript();

	// $("button.t ,.cs-sm-audio button").click(function(){
	//   $('.visually-hidden').slideToggle();
	// })

});

$.fn.transcript = function(){
	console.log("hide all transcript messages by default");
	$(this).find(".audio-transcript").hide();
	$(this).find("button").on("click", function(){
		if($(this).siblings(".audio-transcript").is(":visible")){
			$(this).siblings(".audio-transcript").slideUp();
		}
		else{
			$(".audio-transcript").hide();
			$(this).siblings(".audio-transcript").slideDown();
		}
	});
}




        